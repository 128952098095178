import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from 'styled-components';
import { Button, TopMenu, MenuRow as BaseMenuRow, NavMenu, NavItem, Logo } from 're-cy-cle';
import { Header, Modal, Icon } from 'semantic-ui-react';
import { Route, withRouter } from 'react-router-dom';
import ImgLogo from 'image/logo.svg';
import { SmallAvatar } from 'component/UserAvatar';
import { BUILD_INFO } from 'helpers';
import { ModuleRepository } from '_iae/module/repository';

const MyLogo = () => (
    <Logo>
        <img src={ImgLogo} height="25" alt="logo" style={{ transform: 'translateY(3px)' }} />
    </Logo>
);

const MenuRow = styled(BaseMenuRow)`
    background-color: ${({ theme }) => theme.primaryColor};
    color: #FFF;

    .nav-item:before {
        border-bottom-color: ${({ theme }) => theme.toolbarColor};
    }

    &:nth-child(even) {
        background-color: ${({ theme }) => theme.toolbarColor}
        color: ${({ theme }) => theme.primaryColor};
    }
`;

const ErrorCounter = styled.div`
    background:rgba(255,0,0,0.7);
    color:white;
    font-weight: 800;
    font-size: 0.6rem;
    padding:0 0.5rem;
    border-radius: 7.5px;
    max-height: min-content;
    margin-left: -2.5em;
    //width: 2.5em;
    margin-top: 10px;
    line-height: 1rem;
`

@withRouter
@observer
export default class AppHeader extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        moduleRepository: PropTypes.instanceOf(ModuleRepository).isRequired,
    };

    @observable debug = false;
    @observable showLogoutModal = false;

    toggleDebug = () => {
        this.debug = !this.debug;

        if (this.debug) {
            localStorage.setItem('debug', true);
        } else {
            localStorage.removeItem('debug');
        }
    }

    constructor(...args) {
        super(...args);
        this.debug = !!localStorage.getItem('debug');
    }

    hasPermission = perms => {
        return this.props.store.currentUser.hasPermission(perms);
    };

    renderSupplyChain = () => {
        return (
            <NavMenu>
                {(this.hasPermission('supply_chain.manage_salesorder', 'supply_chain.manage_purchaseorder')) && (
                    <NavItem
                        title={t('nav.supplyChain.distributionManager')}
                        to="/supply-chain/distribution-manager"
                        activePath="/supply-chain/distribution-manager"
                    />
                )}
                {(this.hasPermission('supply_chain.manage_transportorder') || this.hasPermission('supply_chain.manage_transportorder:transport_partner')) && (
                    <NavItem
                        title={t('nav.supplyChain.transportOrders')}
                        to="/supply-chain/transport-order/overview"
                        activePath="/supply-chain/transport-order/"
                    />
                )}
                {this.hasPermission('supply_chain.manage_salesorder') && (
                    <NavItem
                        title={t('nav.supplyChain.distributionOrders')}
                        to="/supply-chain/distribution-order/overview"
                        activePath="/supply-chain/distribution-order/"
                    />
                )}
                {this.hasPermission('supply_chain.manage_salesorder') && (
                    <NavItem
                        title={t('nav.supplyChain.salesOrders')}
                        to="/supply-chain/sales-order/overview"
                        activePath="/supply-chain/sales-order/"
                    />
                )}
                {this.hasPermission(['supply_chain.manage_purchaseorder', 'supply_chain.manage_purchaseorder:supplier']) && (
                    <NavItem
                        title={t('nav.supplyChain.purchaseOrders')}
                        to="/supply-chain/purchase-order/overview"
                        activePath="/supply-chain/purchase-order/"
                    />
                )}
                {this.hasPermission('supply_chain.manage_purchaseorder') && (
                    <NavItem
                        title={t('nav.supplyChain.articles')}
                        to="/supply-chain/article/overview"
                        activePath="/supply-chain/article/"
                    />
                )}
            </NavMenu>
        );
    };

    renderJanKrediet = () => {
        const { store } = this.props;
        return <NavMenu>
            <NavItem
                title={t('nav.janKrediet.orders')}
                to="/jan-krediet/order/overview"
                activePath="/jan-krediet/order/"
            />
            {store.janKredietOrderSyncErrors > 0 &&
                <ErrorCounter data-test-order-sync-errors>
                    {store.janKredietOrderSyncErrors}
                </ErrorCounter>}
            {store.currentUser.hasPermission('supply_chain.manage_transportorder') && <>
                <NavItem
                    title={t('nav.janKrediet.inboundOrders')}
                    to="/jan-krediet/inbound-order/overview"
                    activePath="/jan-krediet/inbound-order/"
                />
                {store.janKredietInboundOrderSyncErrors > 0 &&
                    <ErrorCounter data-test-inbound-order-sync-errors>
                        {store.janKredietInboundOrderSyncErrors}
                    </ErrorCounter>}
            </>}
            {store.currentUser.hasPermission('supply_chain.manage_salesorder') && <>
                <NavItem
                    title={t('nav.janKrediet.outboundOrders')}
                    to="/jan-krediet/outbound-order/overview"
                    activePath="/jan-krediet/outbound-order/"
                />
                {store.janKredietOutboundOrderSyncErrors > 0 &&
                    <ErrorCounter data-test-outbound-order-sync-errors>
                        {store.janKredietOutboundOrderSyncErrors}
                    </ErrorCounter>}
            </>}
        </NavMenu>;
    };

    renderSupplier = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.supplier.dashboard')}
                    to="/supplier/overview"
                    activePath="/supplier/overview"
                />
                {this.hasPermission('supply_chain.manage_purchaseorder:supplier',) && (
                    <NavItem
                        title={t('nav.supplyChain.purchaseOrders')}
                        to="/supplier/purchase-order/overview"
                        activePath="/supplier/purchase-order/overview"
                    />
                )}
                {this.props.store.currentUser.supplier.dropshipment && (
                    <NavItem
                        title={t('nav.supplyChain.transportOrders')}
                        to="/supplier/transport-order/overview"
                        activePath="/supplier/transport-order/overview"
                    />
                )}
            </NavMenu>);
    };


    renderAssets = () => {
        const { store } = this.props;
        return (
            <NavMenu>
                {this.hasPermission('assets.manage_user') && (
                    <NavItem
                        title={t('nav.assets.users')}
                        to="/assets/user/overview"
                        activePath="/assets/user/"
                    />
                )}
                {this.hasPermission('assets.manage_globalvalue') && (
                    <NavItem
                        title={t('nav.assets.globalValues')}
                        to="/assets/global-value/overview"
                        activePath="/assets/global-value/"
                    />
                )}
                {/*this.hasPermission('assets.manage_globalfile') && (
                    <NavItem
                        title={t('nav.assets.globalFiles')}
                        to="/assets/global-file/overview"
                        activePath="/assets/global-file/"
                    />
                )*/}
                {this.hasPermission('supply_chain.manage_transportcompany') && (
                    <NavItem
                        title={t('nav.assets.transportCompanies')}
                        to="/assets/transport-company/overview"
                        activePath="/assets/transport-company/"
                    />
                )}
                {this.hasPermission('exact.sync_exactintegration', 'jankrediet.sync_jankredietintegration') && (
                    <NavItem
                        title={t('nav.assets.integrations')}
                        to="/assets/integration/overview"
                        activePath="/assets/integration/"
                    />
                )}
                {this.hasPermission('exact.sync_exactintegration') && (
                    <><NavItem
                        title={t('nav.assets.exactErrors')}
                        to="/assets/exact-error/overview"
                        activePath="/assets/exact-error/"
                    />
                    {store.exactSyncErrors > 0 && <ErrorCounter>{store.exactSyncErrors}</ErrorCounter>}
                    </>
                )}
                {this.hasPermission('email_templates.manage_emailtemplate') && <NavItem
                    title={t('nav.assets.emailTemplates')}
                    to="/assets/email-template/overview"
                    activePath="/assets/email-template/"
                />}
            </NavMenu>
        );
    };

    renderAudit = () => {
        return (
            <NavMenu>
                {this.hasPermission('audit.view_accesslogentry') && (
                    <NavItem
                        title={t('nav.audit.accessLog')}
                        to="/audit/access-log/overview"
                        activePath="/audit/access-log/"
                    />
                )}
            </NavMenu>
        );
    }

    renderAccountMenu = () => {
        const { store } = this.props;
        const { version, branch } = BUILD_INFO;

        return (
            <NavItem
                title={
                    <span>
                        <SmallAvatar user={store.currentUser}/>
                        {' '}
                        {store.currentUser.fullName} (
                        {branch && branch !== 'master' && branch + ' '}
                        {version}
                        )
                    </span>
                }
                to="/account/details"
                activePath="/account/"
            />
        );
    };

    renderAccount = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.account.account')}
                    to="/account/details"
                />
                <React.Fragment>
                    <NavItem title={t('nav.account.changelog')} to="/account/changelog"/>
                </React.Fragment>
            </NavMenu>
        );
    };

    render() {
        const { currentUser } = this.props.store;
        const { moduleRepository, store } = this.props;

        if (!this.props.store.isAuthenticated || (
            currentUser.groupNames === undefined &&
            !currentUser.isSuperuser
        )) {
            return null;
        }

        const logoutModal = (
            <Modal closeIcon open={this.showLogoutModal} onClose={() => this.showLogoutModal = false} basic
                   size='small'>
                <Header icon='archive' content={t('user.account.logoutButton')}/>
                <Modal.Content>
                    <p>
                        {t('user.account.logout.confirm')}
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color='red' inverted onClick={() => this.showLogoutModal = false}>
                        <Icon name='remove'/> {t('form.no')}
                    </Button>
                    <Button color='green' inverted onClick={() => {
                        this.props.store.performLogout().then(() => {
                            this.showLogoutModal = false;
                        });
                    }}>
                        <Icon name='checkmark'/> {t('form.yes')}
                    </Button>
                </Modal.Actions>
            </Modal>
        );

        let menu = currentUser.isSuperuser ? (
            <TopMenu>
                <MenuRow>
                    <MyLogo/>
                    <NavMenu>
                        <NavItem
                            title={t('nav.assets.users')}
                            to="/assets/user/overview"
                            activePath="/assets/user/"
                        />
                        <NavItem
                            title={t('nav.audit.accessLog')}
                            to="/audit/access-log/overview"
                            activePath="/audit/access-log/"
                        />
                        {moduleRepository.modules.map(module => {
                            return module.navigationMenu().topMenuItem;
                        })}
                    </NavMenu>
                    {this.renderAccountMenu()}
                </MenuRow>
                <MenuRow>
                    <Route path="/account" render={this.renderAccount}/>
                    {/* Hackity hack hack to always show second menu bar */}
                    <NavItem title=" " to="/" activePath="/neverusethispath"/>
                </MenuRow>
            </TopMenu>
        ) : (
            <TopMenu>
                <MenuRow>
                    <MyLogo/>
                    <NavMenu>
                        {this.hasPermission([
                            'supply_chain.manage_purchaseorder',
                            'supply_chain.manage_transportorder',
                            'supply_chain.manage_salesorder',
                            'supply_chain.manage_transportorder:transport_partner',
                        ]) && (
                            <NavItem
                                title={t('nav.main.supplyChain')}
                                to="/supply-chain/distribution-manager"
                                activePath="/supply-chain/"
                            />
                        )}
                         {this.hasPermission([
                            'supply_chain.manage_salesorder',
                            'supply_chain.manage_transportorder',
                        ]) && (<><NavItem
                            title={t('nav.main.janKrediet')}
                            to="/jan-krediet/order/overview"
                            activePath="/jan-krediet/"
                             />
                                 {store.janKredietErrors > 0 && <ErrorCounter data-test-jan-krediet-errors>{store.janKredietErrors}</ErrorCounter>}
                             </>
                         )}
                        {this.hasPermission([
                            'supply_chain.manage_purchaseorder',
                            'supply_chain.manage_purchaseorder:supplier',
                        ]) && (
                            <NavItem
                                title={t('nav.main.supplier')}
                                to="/supplier/overview"
                                activePath="/supplier/"
                            />
                        )}
                        {this.hasPermission([
                            'assets.manage_user',
                            'assets.manage_globalvalue',
                            // 'assets.manage_globalfile',
                            'supply_chain.manage_transportcompany',
                            'exact.sync_exactintegration',
                        ]) && (<>
                            <NavItem
                                title={t('nav.main.assets')}
                                to="/assets/user/overview"
                                activePath="/assets/"
                            />
                            {store.exactSyncErrors > 0 && <ErrorCounter>{store.exactSyncErrors}</ErrorCounter>}
                            </>
                        )}
                    </NavMenu>
                    {this.renderAccountMenu()}
                </MenuRow>
                <MenuRow>
                    <Route path="/supply-chain/" render={this.renderSupplyChain}/>
                    <Route path="/jan-krediet/" render={this.renderJanKrediet}/>
                    <Route path="/supplier/" render={this.renderSupplier}/>
                    <Route path="/account" render={this.renderAccount}/>
                    <Route path="/assets/" render={this.renderAssets}/>
                    <Route path="/audit/" render={this.renderAudit}/>
                </MenuRow>
            </TopMenu>
        );

        return (
            <React.Fragment>
                {menu}
                {logoutModal}
            </React.Fragment>
        );
    }
}
